// External Dependencies
import React from 'react';

// Internal Dependencies
import ContactUsCards from '../components/ContactUsCards';
import ContactUsSection from '../components/ContactUsSection';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import CurveSection from '../components/shared/CurveSection';
import HeaderCurve from '../components/HeaderCurve';

// Component definition
const ContactSupport = () => {
  return (
    <Layout variant="curve">
      <Meta title="Contact the Presto Support Team" />

      <HeaderCurve
        title="Get your questions answered"
        subtitle="Let us know your questions, feedback, and suggestions. We're happy to help!"
      />

      <div className="contact" id="main">
        <CurveSection
          className="main"
          id="content"
        >
          <ContactUsSection />
        </CurveSection>

        <ContactUsCards />
      </div>

    </Layout>
  );
};

export default ContactSupport;

// External Dependencies
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import React from 'react';

// Internal Dependencies
import {
  mailtoInfoFromContactPage,
  mainPhoneNumber,
  mainPhoneNumberLink,
} from '../utils/constants';

// Component Definition
const ContactUsSection = () => {
  return (
    <Container maxWidth="md">
      <Card className="cta contact-us-card">
        <div className="contact-us-card-content">
          <h2>Contact Us</h2>

          <p className="contact-us-card-text">
            Bring us your questions, feedback, and suggestions.
            Contact Presto Assistant, and we&apos;ll get you back on track.
          </p>

          <div className="cta-wrapper">
            <div>
              <a
                className="transparent-button"
                data-variant="transport"
                href={mailtoInfoFromContactPage}
                rel="noopener noreferrer"
                target="_blank"
              >
                Email us
              </a>
            </div>

            <div>
              <a
                className="transparent-button"
                data-variant="transport"
                href={mainPhoneNumberLink}
              >
                {mainPhoneNumber}
              </a>
            </div>
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default ContactUsSection;

// External Dependencies
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
  mailtoSupportFromContactPage,
  mainPhoneNumber,
  supportEmailAddress,
  supportSiteUrl,
} from '../utils/constants';
import facebookLogo from '../assets/images/social--icon-facebook-dark.svg';
import instagramLogo from '../assets/images/social--icon-instagram-dark.svg';
import linkedInLogo from '../assets/images/social--icon-linkedin-dark.svg';
import twitterLogo from '../assets/images/social--icon-twitter-dark.svg';
import youTubeLogo from '../assets/images/social--icon-youtube-dark.svg';

// Component Definition
const ContactUsCards = () => {
  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-around"
        gap={4}
      >
        <Card
          className="contact-card"
          data-variant="outlined"
          elevation={3}
        >
          <CardHeader title="Hours of Operation" />

          <CardContent className="contact-card-content">
            <p className="contact-card-icon-wrapper">
              <img
                alt="chat-icon"
                className="contact-card-icon"
                src="https://res.cloudinary.com/presto-assistant/image/upload/v1714953977/Chat_lchqfx.png"
              />
            </p>

            <div className="contact-card-text-block">
              <p>Monday-Friday</p>
              <p>8:00 AM - 5:30 PM Central Time</p>
            </div>

            <div className="contact-card-call-or-text">
              <strong>Phone</strong><br />
              <strong>{mainPhoneNumber}</strong>
            </div>
          </CardContent>
        </Card>

        <Card
          className="contact-card"
          data-variant="outlined"
          elevation={3}
        >
          <CardHeader title="Contact Support" />

          <CardContent className="contact-card-content">
            <p className="contact-card-icon-wrapper">
              <img
                alt="chat-icon"
                className="contact-card-icon"
                src="https://res.cloudinary.com/presto-assistant/image/upload/v1714953977/Email_tsayj9.png"
              />
            </p>

            <p>
              Support:{' '}
              <a
                href={mailtoSupportFromContactPage}
                rel="noopener noreferrer"
                target="_blank"
              >
                {supportEmailAddress}
              </a>
            </p>

            <p>
              Online:{' '}
              <a
                href={supportSiteUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                Support articles
              </a>
            </p>

            <div className="contact-card-social-links-wrapper">
              <a href={FACEBOOK_LINK} rel="noopener noreferrer" target="_blank">
                <img alt="facebook logo" src={facebookLogo} />
              </a>

              <a href={INSTAGRAM_LINK} rel="noopener noreferrer" target="_blank">
                <img alt="instagram logo" src={instagramLogo} />
              </a>

              <a href={LINKEDIN_LINK} rel="noopener noreferrer" target="_blank">
                <img alt="linkedin logo" src={linkedInLogo} />
              </a>

              <a href={TWITTER_LINK} rel="noopener noreferrer" target="_blank">
                <img alt="twitter/x logo" src={twitterLogo} />
              </a>

              <a href={YOUTUBE_LINK} rel="noopener noreferrer" target="_blank">
                <img alt="youtube logo" src={youTubeLogo} />
              </a>

            </div>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default ContactUsCards;
